<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditTotalSeats.Header_ModalHeader')
    "
    modalSize="large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <Message
        :message="
          $t('Components.Reservation.ModalEditTotalSeats.Text_Explanation')
        "
      />

      <div v-if="!isLocked && isEmployee && mReservation.MeetingtypeId !== 2">
        <span class="has-text-weight-bold">{{
          $t('Components.Reservation.ModalEditTotalSeats.Label_TotalSeats')
        }}</span>
        <input
          type="text"
          step="1"
          maxLength="4"
          v-model.number="mReservation.TotalSeats"
          class="input"
        />
      </div>

      <table class="table is-fullwidth is-striped ">
        <thead>
          <tr>
            <th>
              {{
                $t('Components.Reservation.ModalEditTotalSeats.Label_Quantity')
              }}
            </th>
            <th>
              {{
                $t(
                  'Components.Reservation.ModalEditTotalSeats.Label_AffectedOptions'
                )
              }}
            </th>
            <th width="100" class="has-text-right">
              {{
                $t('Components.Reservation.ModalEditTotalSeats.Label_Change')
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(option, index) in affectedOptions" :key="index">
            <td>
              <span v-if="!option.change">{{ option.originalQuantity }}x</span>
              <span v-if="option.change">{{ mReservation.TotalSeats }}x</span>
            </td>
            <td>{{ option.name }}</td>
            <td class="has-text-right">
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': option.change === true }">
                    <a @click="option.change = true">
                      <span>{{
                        $t(
                          'Components.Reservation.ModalEditTotalSeats.Label_Yes'
                        )
                      }}</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': option.change === false }">
                    <a @click="option.change = false">
                      <span>{{
                        $t(
                          'Components.Reservation.ModalEditTotalSeats.Label_No'
                        )
                      }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      affectedOptions: [],
      mReservation: null,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))

    this.fillAffectedOptions()
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    fillAffectedOptions() {
      let resOptions = this.mReservation.Options.filter((o) => o.IsPP)
      for (let i = 0; i < resOptions.length; i++) {
        let option = resOptions[i]

        this.affectedOptions.push({
          id: option.Id,
          name: option.Name,
          originalQuantity: option.Amount,
          change: option.Amount === this.mReservation.TotalSeats,
        })
      }
    },

    saveReservation() {
      let self = this

      for (let i = 0; i < self.mReservation.Options.length; i++) {
        let resOption = self.mReservation.Options[i]
        let option = self.affectedOptions.find((o) => o.id === resOption.Id)

        if (option && option.change) {
          self.mReservation.Options[i].Amount = self.mReservation.TotalSeats
        }
      }

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .saveReservation(self.mReservation, self.changeAllInSet)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              // Trigger success animation
              self.isSavingSuccess = true

              // Send message to be shown as toast on page
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Reservation is saved!',
              })

              // Set timer to self close the modal after 1.5 seconds
              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
